@import "../../variables";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.homepage-layout-image-set-background-color {
    background-color: $white;
}

.homepage-layout-image-set-wrapper {
    padding: 48px 24px;
    gap: 40px;

    @include media-breakpoint-up(md) {
        padding: 48px 0;
        margin: 0 80px;
        max-width: 1400px;
    }

    .homepage-layout-image-set-container {
        gap: 40px;

        @include media-breakpoint-up(md) {
            gap: 32px;
        }
    }

    .homepage-layout-image-set-module {
        max-width: 327px;
        gap: 16px;

        > a {
            &:hover {
                img {
                    opacity: 0.75;
                }
            }
        }
    }

    .homepage-layout-image-set-module-text {
        gap: 8px;
    }

    .homepage-layout-image-set-module-image {
        max-height: 327px;
        object-fit: cover;
        border-radius: 24px;
    }

    .homepage-layout-image-set-module-label {
        color: $dark-green;
        font-family: $font-qualion;
        font-size: 24px;
        font-weight: 700;
        line-height: 36px;

        a {
            color: $dark-green;

            &:hover {
                color: $light-green-tertiary;
                text-decoration: none;
            }
        }
    }

    .homepage-layout-image-set-module-description {
        color: $dark-green;
        font-family: $font-qualion;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
    }

    .homepage-layout-image-set-module-button {
        height: 56px;
        padding: 16px 24px;
        border-radius: 4px;
        border: 2px solid $light-green;
        color: $light-green;
        font-family: $font-qualion;
        font-size: 18px;
        font-weight: 700;
        line-height: 26px;
        text-decoration: none !important;

        &:hover {
            border-color: $light-green-tertiary;
            color: $light-green-tertiary;
            opacity: 0.5;
        }
    }
}